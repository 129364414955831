/* src/styles/auth.css */
.auth-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.auth-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.auth-container form div {
    margin-bottom: 15px;
}

.auth-container label {
    display: block;
    margin-bottom: 5px;
}

.auth-container input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.auth-container .error {
    color: red;
    margin-bottom: 15px;
}

.auth-container .message {
    color: green;
    margin-bottom: 15px;
}

.auth-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.auth-container button:hover {
    background-color: #0056b3;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
}

.navbar a {
    margin-right: 15px;
    text-decoration: none;
    color: #333;
}

.navbar .navbar-links span {
    margin-right: 15px;
}
